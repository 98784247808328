.modal-example {
  .modal-dialog {
    max-width: rem(600px);
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 0;
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
  }
}

.modal-notification {
  .modal-dialog {
    max-width: rem(600px);
  }
  .modal-body {
    padding: 0;
  }
  .modal-inner {
    padding: 2rem 2rem 0;
  }
  h5 {
    font-size: rem(30px);
    font-family: $fontTitle;
    color:cl(primary);
    letter-spacing: rem(.2px);
    font-weight: normal;
    margin: rem(0 0 20px);
    text-align: center;
  }
  p {
    margin: rem(0 0 15px);
  }
  img {
    display: block;
    max-width:100%;
    margin:0 auto 2rem;
  }
  video{
    display: block;
    width:100%;
    height: auto;
    margin-bottom: 2rem;
  }
  .modal-iframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin: 0 0 2rem;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 0;
    font-size: rem(16px);
    line-height: calc(24 / 16);
    color:cl(text);
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close-black.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
  }
  iframe {
    display: block;
    width:100%;
    height: rem(700px);
    border:0;
    @include media-breakpoint-down(xs) {
      height: 65rem;
    }
  }
  .fadeIn{
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
  @keyframes fadeIn {
    0% {
      transform: scale(0);
      opacity: 0.0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      -webkit-transform: scale(0);
      opacity: 0.0;
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 1;
    }
  }
}
.modal-example {
  .modal-dialog {
    max-width: rem(600px);
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 0;
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
  }
}

.modal-video {
  .modal-dialog {
    max-width: rem(850px);
  }
  h5 {
    font-size: rem(40px);
    font-family: $fontTitle;
    color:cl(primary);
    letter-spacing: rem(.2px);
    font-weight: normal;
    margin: rem(0 0 15px);
  }
  img {
    display: block;
    max-width:100%;
    margin-bottom: 1rem;
  }
  video{
    display: block;
    width:100%;
    height: auto;
    margin-bottom: 1rem;
  }
  .modal-iframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin: 0 0 1rem;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 1rem 1rem 0;
    font-size: rem(18px);
    line-height: 1.3;
    color:cl(text);
    @include media-breakpoint-up(sm) {
      //padding: rem(20px);
    }
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
  }
  iframe {
    display: block;
    width:100%;
    height: rem(700px);
    border:0;
    @include media-breakpoint-down(xs) {
      height: 65rem;
    }
  }
}