.logo {
  &--main {
    display: flex;
    align-items: center;
    gap:rem(16px);
    flex-shrink: 0;
    &:hover {
      text-decoration: none;
    }
  }
  &--nav {
    display: none;
    width:rem(112px);
    height: auto;
    top:1rem;
    @extend .pos-centerX;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
  &--footer {
    display: block;
    width:rem(150px);
    max-width:100%;
    @include media-breakpoint-down(xs) {
      margin: 0 auto 1rem;
    }
  }
  &--cognia {
    display: inline-block;
    vertical-align: top;
    width:rem(110px);
    max-width:100%;
    @include media-breakpoint-down(xs) {
      width:rem(90px);
    }
  }
  &--somerset {
    display: inline-block;
    vertical-align: top;
    width:rem(150px);
    max-width:100%;
    @include media-breakpoint-down(xs) {
      width:rem(100px);
    }
  }
  &--fortify {
    display: inline-block;
    vertical-align: top;
  }
  &__image {
    width:rem(112px);
    flex-shrink: 0;
    transition: width .3s ease;
    @include media-breakpoint-down(lg) {
      width:rem(100px);
    }
  }
  &__title {
    color:cl(primary);
    text-transform: uppercase;
    font-size: rem(32px);
    line-height: 1.1;
    font-family: $fontTitle;
    font-weight: normal;
    transition: font-size .3s ease;
    @include media-breakpoint-down(sm) {
      font-size: rem(26px);
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
}