.contact {
  &--section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    &:before {
      content: "";
      @extend .full;
      background: cl(primary, .66);
    }
  }
  &__inner {
    background: url(../img/wave-top-small-mobile.png) no-repeat 50% 0;
    background-size: 100% auto;
    position: relative;
    z-index: 2;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      background: url(../img/wave-top-small.png) no-repeat 50% 0;
      background-size: 100% auto;
    }
    @include media-breakpoint-down(xs) {
      padding: 2rem 0 0;
    }
    &--padding {
      padding-top: 12%;
      background: url(../img/wave-top-small.png) no-repeat 50% 0;
      background-size: 100% auto;
    }
  }
  &__holder {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: rem(354px);
  }
  &__leaf {
    position: absolute;
    width:rem(150px);
    height: auto;
    transform: rotate(75deg);
    top: 10%;
    left: 5%;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      width:rem(100px);
      top:0
    }
    @include media-breakpoint-down(xs) {
      width:rem(80px);
    }
  }
  &__decor {
    position: absolute;
    width:rem(150px);
    height: auto;
    transform: rotate(-240deg) scaleX(-1);
    bottom: rem(-90px);
    right: rem(-50px);
    z-index: 2;
  }
  .quick-links {
    display: flex;
    justify-content: space-between;
    padding: rem(40px 0 0);
    position: relative;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      gap:rem(20px);
    }
    &__link {
      display: flex;
      align-items: center;
      gap:rem(20px);
      text-transform: uppercase;
      background: cl(white);
      border-radius: rem(25px);
      width:48%;
      height: rem(180px);
      padding: rem(0 40px);
      box-shadow:rem(5px 30px 60px cl(black, .34));
      &:hover,
      &:focus {
        text-decoration: none;
        background: cl(primary);
        strong,
        b {
          color:cl(white);
        }
      }
      @include media-breakpoint-down(xs) {
        width:100%;
      }
      img {
        width:rem(80px);
        height: auto;
      }
      strong {
        display: block;
        color: cl(primary);
        font-size: rem(40px);
        font-weight: normal;
        font-family: $fontTitle;
        margin: rem(0 0 10px);
      }
      b {
        display: block;
        color: cl(black);
        font-size: rem(20px);
      }
    }
  }
}