

/* **************** bootstrap ****************** */
@import "bootstrap/bootstrap";

/* **************** fontawesome ****************** */
@import "fontawesome/fontawesome";
@import "fontawesome/brands";
@import "fontawesome/regular";
@import "fontawesome/solid";

/* **************** INCLUDES ****************** */
@import "includes/rem";
@import "includes/base";
@import "includes/fonts";
@import "includes/variables";
@import "includes/common";

/* **************** loader ****************** */
@import "loader/loaders";

/* **************** plugins ****************** */
@import "plugins/slick";

/* **************** PAGE COMPONENTS ****************** */
@import "page-components/buttons";
@import "page-components/header";
@import "page-components/footer";
@import "page-components/banner";
@import "page-components/title";
@import "page-components/logo";
@import "page-components/about";
@import "page-components/info";
@import "page-components/contact";
@import "page-components/campuses";
@import "page-components/sidebar";
@import "page-components/files";
@import "page-components/faq";
@import "page-components/news";
@import "page-components/album";
@import "page-components/filter";
@import "page-components/events";
@import "page-components/search";
@import "page-components/staff";
@import "page-components/custom-list";
@import "page-components/form";
@import "page-components/modal";
@import "page-components/static";
@import "page-components/cookie";
@import "page-components/error";

