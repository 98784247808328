/* banner start */
.banner {
  position: relative;
  overflow: hidden;
  z-index: 2;
  &--home {
    height: 100vh;
    @include media-breakpoint-down(lg) {
      height: 60rem;
    }
    @include media-breakpoint-down(sm) {
      height: 40rem;
    }
    @include media-breakpoint-down(xs) {
      height: auto;
      margin-top: 10rem;
    }
    .banner__bottom {
      @include media-breakpoint-down(xs) {
        bottom: auto;
        top:calc(18rem - 5rem);
      }
    }
  }
  &--page {
    margin-top: rem(160px);
    height: rem(520px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(xs) {
      height: 20rem;
    }
    &:before{
      content: "";
      @extend .full;
      background: cl(black, 0.2);
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: rem(70px);
      line-height: 1;
      font-weight: normal;
      font-family: $fontTitle;
      letter-spacing: rem(.2px);
      margin: rem(0 0 20px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        font-size: rem(50px);
      }
    }
  }
  &__video {
    overflow: hidden;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    height: 18rem;
    @include media-breakpoint-up(sm) {
      position: absolute;
      height: 100%;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    video[poster]{
      object-fit: cover;
    }
  }
  &__text {
    position: relative;
    width:100%;
    height: 100%;
    z-index: 6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: rem(50px 0 0 205px);
    @include media-breakpoint-down(lg) {
      padding-left: 3rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 1rem 0 2rem;
      justify-content:center;
    }
  }
  &__circle {
    color:cl(white);
    width:rem(550px);
    height:rem(550px);
    border-radius: 50%;
    background: cl(primary);
    text-align: center;
    box-shadow:rem(-30px 10px 0 cl(white, 0.74) , 0px 11px 27px cl(black, .44));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: rem(50px);
    font-size: rem(18px);
    line-height: 1.5;
    position: relative;
    @include media-breakpoint-down(xs){
      width:25rem;
      height:25rem;
      font-size: rem(16px);
      box-shadow:rem(-20px 5px 0 cl(primary, 0.3) , 0px 11px 27px cl(black, .44));
    }
    h1 {
      font-size: rem(40px);
      font-weight: normal;
      line-height: 1.25;
      font-family: $fontTitle;
      margin: rem(0 0 20px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        font-size: rem(26px);
        word-wrap: break-word;
      }
    }
    p {
      margin: 0;
    }
  }
  &__icon {
    width:rem(44px);
    margin: rem(0 0 35px);
    @include media-breakpoint-down(xs) {
      width:rem(20px);
      margin: rem(0 0 15px);
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    left:0;
    width:100%;
    height:rem(100px);
    @include media-breakpoint-down(xs) {
      height: 5.25rem;
    }
  }
  &__leaf {
    position: absolute;
    width:rem(140px);
    height: auto;
    transform: rotate(-240deg) scaleX(-1);
    bottom: rem(-15px);
    right: 0;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      width:rem(100px);
    }
  }
  &__waves {
    position:absolute;
    left:0;
    bottom: 0;
    width: 100%;
    height:100%;
    margin-bottom:rem(-1px); /*Fix for safari gap*/
    z-index: 100;
    @include media-breakpoint-down(xs) {
      height:rem(80px);
    }
    svg,
    img{
      display: block;
      width:100%;
      height: 100%;
    }
  }
  &__decor {
    position: absolute;
    width:rem(140px);
    height: auto;
    transform: rotate(-70deg) scaleX(-1);
    bottom: 0;
    right: 5%;
    z-index: 102;
    @include media-breakpoint-down(xs) {
      width:rem(100px);
    }
  }
}
/* banner end */

