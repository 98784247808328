.campuses {
  position: relative;
  padding: rem(0 80px 0 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-up(xl) {
    margin: rem(56px 0 0);
  }
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 0;
  }
  @include media-breakpoint-down(xs) {
    padding: 4rem 0 0;
  }
  .title {

  }
  &__content {

  }
  &__slider {
    background: cl(white);
    box-shadow:rem(0 22px 54px cl(black, .09));
    height: rem(180px);
    border-radius: rem(90px);
    padding: rem(20px);
    width:rem(850px);
    @include media-breakpoint-down(xs) {
      width:100%;
    }
    .slick-list,
    .slick-track,
    .item {
      height: 100%;
    }
  }
  &__item {
    display: block;
    margin: rem(0 10px);
    height: 100%;
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__buttons {
    position: relative;
    display: flex;
    gap:rem(8px);
    @include media-breakpoint-up(xl) {
      position: absolute;
      right:0;
      top:rem(65px);
    }
    @include media-breakpoint-down(lg) {
      margin-top: 2rem;
    }
  }
  &__btn {
    width:rem(50px);
    height: rem(50px);
    background: cl(white);
    color:cl(secondary);
    border-radius: 50%;
    border:0;
    box-shadow:rem(0 11px 27px cl(black, .09));
    font-size: rem(18px);
    &:hover,
    &:focus {
      background: cl(secondary);
      color:cl(white);
    }
  }
}