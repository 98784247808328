.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: cl(text);
	min-height: 100vh;
	background: cl(white);
	font-family: $font;
	line-height: 1.1;
	@include rem-baseline;
	font-size: calc(10.75px + 15 * ((100vw - 1200px) / 2048));
	@media screen and (min-width: 2049px) {
		font-size: calc(20px + 15 * ((100vw - 2049px) / 4096));
	}
	@include media-breakpoint-down(lg) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(xs) {
		font-size: calc(13px + 2 * ((100vw - 320px) / 575.98));
	}
}
img {
	vertical-align: top;
}
a {
	color: cl(primary);
	text-decoration: none;
	&:hover {
		color: cl(primary);
		text-decoration: underline;
	}
}

.no-scroll {
	overflow: hidden;
}

.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.container {
	--size: 76.875rem;
	max-width: var(--size);
	&--small {
		--size: 800px;
	}
}
.wrapper {
	padding: rem(0 190px);
}

.apply {
	position: sticky;
	left:0;
	bottom: 0;
	width:100%;
	z-index: 199;
	box-shadow: rem(0 -3px 5px cl(black, .2));
	@include media-breakpoint-up(sm) {
		display: none;
	}
	a {
		width:100%;
	}
}
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: cl(white);
}
@include media-breakpoint-down(lg){
	//.wow {
	//	visibility: visible !important;
	//	-webkit-animation: none !important;
	//	-moz-animation: none !important;
	//	-o-animation: none !important;
	//	-ms-animation: none !important;
	//	animation: none !important;
	//}
}
