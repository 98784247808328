.about {
  &--section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    z-index: 3;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    &:before {
      content: "";
      @extend .full;
      background: cl(primary, .42);
    }
    &:after {
      content: "";
      position: absolute;
      bottom:-2px;
      left:0;
      width:100%;
      height: 4px;
      background: cl(white);
    }
  }
  &__inner {
    position: relative;
    z-index: 2;
    padding: 0 0 8%;
    @include media-breakpoint-up(xl) {
      min-height: 100vh;
    }
    @include media-breakpoint-up(lg) {
      background: url(../img/wave-top.png) no-repeat 50% 0;
      background-size: 100% auto;
    }
    &:before {
      content: "";
      @extend .full;
      background: url(../img/wave-bottom.png) no-repeat 50% 100%;
      background-size: 100% auto;
    }
  }
  .container {
    position: relative;
    z-index: 4;
  }
  &__text {
    font-size: rem(20px);
    line-height: 1.5;
    @include media-breakpoint-down(md) {
      background: cl(white);
      margin: 0 -15px;
      padding: 3rem 15px 1rem;
      &:before {
        content: "";
        position: absolute;
        left:0;
        top:98%;
        width:100%;
        height: 7rem;
        background: url(../img/wave-top-small.png) no-repeat 50% 0;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(xs) {
      padding: 3rem 15px;
    }
  }
  &__circle {
    color:cl(white);
    width:rem(697px);
    height:rem(697px);
    border-radius: 50%;
    text-align: center;
    box-shadow:rem(-30px 10px 0 cl(white, 0.74) , 0px 11px 27px cl(black, .44));
    align-items: center;
    position: relative;
    background-color: cl(primary);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @include media-breakpoint-down(lg) {
      width:33rem;
      height: 33rem;
      margin: 0 auto;
    }
    @include media-breakpoint-down(xs) {
      width:rem(300px);
      height:rem(300px);
      box-shadow:rem(-20px 5px 0 cl(white, 0.74) , 0px 11px 27px cl(black, .44));
    }
  }
  &__leaf {
    position: absolute;
    width:rem(150px);
    height: auto;
    transform: rotate(-115deg);
    bottom: 10%;
    left: 0;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      top: 54%;
      right: 0;
      width: 4.75rem;
    }
  }
}