.footer {
  background: cl(white);
  &__top {
    padding: rem(30px 0);
    color:#221d1e;
    font-size: rem(16px);
    line-height: 1.6;
    a {
      color:#221d1e;
      &:hover {
        text-decoration: none;
        color:cl(secondary);
      }
    }
  }
  &__bottom {
    background: cl(primary);
    color: cl(white);
    padding: rem(7px 0);
  }
  &__address {
    @include media-breakpoint-down(xs) {
      padding: rem(30px 0 10px);
    }
  }
  &__supported {
    display: block;
    font-size: rem(14px);
    @include media-breakpoint-down(sm) {
      margin-top: rem(15px);
    }
    img {
      vertical-align: middle;
    }
  }
}

